import axios from 'axios';

export default {
  data: () => ({
    chapmanagents: [],
  }),

  mounted() {
    this.loadAgents();
  },

  methods: {
    async loadAgents() {

      // Submit the form
      try {
        const { data } = await axios.get(`/get/agents`);

        this.chapmanagents = data;

        return true;
      } catch (e) {
        console.log('Error');

        return false;
      }
    },
  },
};
