<template>
    <div class="">
        <div class="flex justify-between items-center mb-6">
            <div>
                <h1 class="text-4xl">Generate Report</h1>
                <!-- <p class="text-sm text-gray-400">Welcome to your dashboard</p> -->
            </div>
        </div>

        <div class="flex flex-col">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div class="shadow p-5 overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <!-- Report Form -->
                        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                            <!-- Report On Select -->
                            <div class="flex flex-col">
                                <label for="reportOn">Report On</label>
                                <select @change="clear()" class="border rounded w-full p-2" v-model="reportOn"
                                    name="report_on" id="reportOn">
                                    <option value="branch_sales_report">Branch Sales Report</option>
                                    <!-- <option value="branch_sales">Branch Sales Report</option> -->
                                    <option value="float_replenishments">Float Replenishments</option>
                                    <option value="account_replenishments">Account Replenishments</option>
                                    <option value="revise_report">Revise Log Report</option>
                                    <option value="balance_report">Balance Report</option>
                                    <option value="agents_sales">Agents Report</option>
                                    <option value="superagents_sales">Superagents Report</option>
                                    <option value="deposits">Deposits</option>
                                    <option value="customer">Customers Report</option>
                                    <option value="worksheets_report">Worksheets Report</option>
                                    <option value="daily_cash_collection_report">Daily Cash Collection Report</option>
                                    <option value="sms_report">SMS Report</option>
                                    <option value="nawec_running_balance">Nawec Running Balance</option>
                                    <option value="daily_cash_reconciliation_report">Daily Cash Reconciliation Report
                                    </option>
                                    <option value="denominator_report">Denominator report</option>
                                    <option value="financial_summary_report">Financial Summary Report</option>
                                    <!-- <option value="nawec_sales_summary_report">Nawec Sales Summary Report</option> -->
                                </select>
                            </div>

                            <!-- Date Inputs -->
                            <template v-if="reportOn !== 'customer'">
                                <div class="flex flex-col">
                                    <label for="from">From</label>
                                    <input class="border rounded w-full p-2" type="date" name="from" v-model="from"
                                        id="from" />
                                </div>
                                <div class="flex flex-col">
                                    <label for="to">To</label>
                                    <input class="border rounded w-full p-2" type="date" name="to" v-model="to"
                                        id="to" />
                                </div>
                            </template>

                            <!-- Additional Options based on Report Type -->
                            <div v-if="reportOn == 'denominator_report'" class="flex mt-6">
                                <div class="flex flex-col mx-2">
                                    <label for="filterBy">Filter by</label>
                                    <select @change="onBranchSelected()" class="border rounded w-full p-2"
                                        v-model="filter_option" name="filterBy" id="filterBy">
                                        <option selected disabled>Filter by</option>
                                        <option value="branch">Branchs</option>
                                        <option value="agent">Agents</option>
                                        <option value="super_agent">Super Agents</option>
                                    </select>
                                </div>
                            </div>

                            <div v-if="reportOn == 'financial_summary_report'" class="flex mt-6">
                                <div class="flex flex-col">
                                    <label for="accounts">Accounts</label>
                                    <select class="border rounded w-full p-2" v-model="account" name="accounts"
                                        id="accounts">
                                        <option selected disabled>Select an account</option>
                                        <option value="all">All</option>
                                        <option :value="acc.id" v-for="(acc, i) in accounts" :key="i">{{ acc.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div v-if="reportOn == 'worksheets_report'" class="flex mt-6 gap-x-4">
                                <div class="flex flex-col w-1/2">
                                    <label for="banked">Banked</label>
                                    <select class="border rounded w-full p-2 mr-4" v-model="isBanked" name="banked"
                                        id="banked">
                                        <option selected disabled>Select</option>
                                        <option value="all">All</option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </select>
                                </div>

                                <div class="flex flex-col w-1/2">
                                    <label for="shortageStatus">Shortage Status</label>
                                    <select class="border rounded w-full p-2" v-model="isShortage" name="shortageStatus"
                                        id="shortageStatus">
                                        <option selected disabled>Select</option>
                                        <option value="all">All</option>
                                        <option value="yes">Shortage</option>
                                        <option value="no">No Shortage</option>
                                    </select>
                                </div>
                            </div>

                            <div v-if="reportOn == 'branch_sales' || reportOn == 'daily_cash_collection_report'"
                                class="flex mt-6 gap-x-2">
                                <div class="flex flex-col w-1/2">
                                    <label for="branch">Branch</label>
                                    <select @change="onBranchSelected()" class="border rounded w-full p-2 mr-4"
                                        v-model="branch" name="branch" id="branch">
                                        <option selected disabled>Select a branch</option>
                                        <option value="all">All</option>
                                        <option :value="branch.id" v-for="(branch, i) in branchs" :key="i">{{
                                    branch.name }}</option>
                                    </select>
                                </div>

                                <div class="flex flex-col w-1/2">
                                    <label for="staff">Staff</label>
                                    <select class="border rounded w-full p-2" v-model="staff" name="staff" id="staff">
                                        <option selected disabled>Select a staff</option>
                                        <option value="all">All</option>
                                        <option :value="staff.id" v-for="(staff, i) in staffs" :key="i">{{
                                    staff.first_name }} {{ staff.last_name }}</option>
                                    </select>
                                </div>
                            </div>

                            <div v-if="reportOn == 'superagents_sales'" class="flex mt-6">
                                <div class="flex flex-col">
                                    <label for="superAgent">Super Agents</label>
                                    <select class="border rounded w-full p-2" v-model="superagent" name="superAgent"
                                        id="superAgent">
                                        <option selected disabled>Select a super agent</option>
                                        <option value="all">All</option>
                                        <option v-for="(agent, i) in agents.data" :value="agent.id" :key="i">{{
                                    agent.entity_name }}</option>
                                    </select>
                                </div>
                            </div>

                            <div v-if="reportOn == 'agents_sales'" class="flex mt-6">
                                <div class="flex flex-col">
                                    <label for="agent">Select Agents</label>
                                    <select class="border rounded w-full p-2" v-model="agent" name="agent" id="agent">
                                        <option selected disabled>Select an agent</option>
                                        <option value="all">All</option>
                                        <option v-for="(agent, i) in chapmanagents.data" :value="agent.id" :key="i">{{
                                    agent.entity_name }}</option>
                                    </select>
                                </div>
                            </div>

                        </div>

                        <!-- Error Message -->
                        <div>
                            <p v-if="showError">Show Error</p>
                        </div>

                        <!-- Generate Button -->
                        <div class="mt-4">
                            <button :disabled="!validation || isLoading" @click="generate()"
                                class="bg-green-400 px-6 py-2 rounded-md hover:bg-green-500 disabled:opacity-50">
                                <span v-if="!isLoading">Generate</span>
                                <span v-else>Loading...</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import SuperAgents from '../../mixins/SuperAgents';
import Agents from '../../mixins/Agents';

export default {
    middleware: 'auth',
    layout: 'default',
    mixins: [SuperAgents, Agents],

    data: () => ({
        branchs: null,
        accounts: [],
        account: null,
        staffs: null,
        isLoading: false,
        staff: 'all',
        isBanked: 'all',
        isShortage: 'all',
        superagent: 'all',
        agent: 'all',
        branch: 'all',
        reportOn: '',
        reportBy: '',
        filter_option_id: '',
        filter_option: '',
        from: '',
        to: '',
        showError: false,
    }),

    computed: {
        validation: function () {
            // console.log('report on', this.reportOn);
            if (this.from == '' || this.to == '' || this.reportOn == '') {
                if (this.reportOn == 'customer') {
                    return true;
                }
                return false;
            }

            return true;
        },
    },

    mounted() {
        this.loadAccounts();
    },

    created() {
        var _this = this;
        _this.loadBranches();
    },

    methods: {
        clear() {
            this.staffs = null;
            this.branch = 'all';
        },

        async generate() {
            this.isLoading = true;
            console.log('staffs///', this.staff);
            console.log('reportOn///', this.reportOn);




            try {
                const { data } = await axios.get(
                    `/report/generate?from=${this.from}&to=${this.to}&superagent=${this.superagent}&agent=${this.agent}&filter_option=${this.filter_option}&filter_option_id=${this.filter_option_id}&report=${this.reportOn}&reportBy=${this.reportBy}&branch=${this.branch}&staff=${this.staff}&account_id=${this.account}&isShortage=${this.isShortage}&isBanked=${this.isBanked} `
                );


                console.log('Report data', data);
                this.$router.push({
                    name: 'generateReportResult',
                    params: {
                        result: data,
                        to: this.to,
                        from: this.from,
                        reportOn: this.reportOn,
                        reportBy: this.reportBy,
                        branch: this.branch,
                        staff: this.staff,
                        isShortage: this.isShortage,
                        isBanked: this.isBanked,
                        agent: this.agent,
                        superagent: this.superagent,
                    },
                });

                this.report = data;
                this.isLoading = false;

                return true;
            } catch (e) {
                console.log('Error', e);
                this.isLoading = false;

                return false;
            }
        },

        async loadAccounts() {
            try {
                const { data } = await axios.get('/accounts');
                this.accounts = data.data;
            } catch (error) {
                console.log('Error', error);
            }
        },

        async loadBranches() {
            // Submit the form

            try {
                const { data } = await axios.get(`/get/branches`);

                this.branchs = data.data;

                // this.isLoading = false;

                return true;
            } catch (e) {
                console.log('Error');
                this.isLoading = false;
                return false;
            }
        },

        onBranchSelected() {
            this.loadStaffs();
        },

        async loadStaffs() {
            // Submit the form
            // console.log("SATFF", id);
            try {
                const { data } = await axios.get(
                    `/staffs/branch/${this.branch}`
                );
                console.log('staffs', data);
                this.staffs = data.data;
                // this.isLoading = false;

                return true;
            } catch (e) {
                console.log('Error');
                this.isLoading = false;
                return false;
            }
        },
    },
};
</script>
